import React from "react"
import Menu from './menu'

import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Header = () => {

  	return (
		<header>
			<div id="topBar">
				<section className="content">
					<div id="logoContainer">
						<div id="logoWrapper">
							<AniLink
								paintDrip hex="#EE82BF"
								to="/"
								duration={0.8}
							>
								<StaticImage src="../images/logo_new_white_small.png" alt="logo" id="headerLogo"/>
							</AniLink>
						</div>
					</div>
					<div id="topContact">
						<a href="mailto:info@m1nd-set.com">info@m1nd-set.com</a>
						<a href="https://www.linkedin.com/company/m1nd-set/" target="_blank" rel="noreferrer" id="linkedinLink" aria-label="Linkedin link">
							<FontAwesomeIcon icon={faLinkedin} size="1x" width="13" />
						</a>
					</div>
				</section>
			</div>
			<div id="subpageHeader">
				<section className="content">
					<Menu id="mainMenu"/>
				</section>
			</div>

		</header>
    )
}

export default Header
