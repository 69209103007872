import React, { useEffect } from 'react'

import SeoWrapper from './seoWrapper'

import Header from "./header"
import Footer from "./footer"

import '../misc/styles.scss';

import AniLink from "gatsby-plugin-transition-link/AniLink";

const Layout = ({ children, title = '', subtitle = '', breadcrumbs = [] }) => {

	useEffect(() => {
		document.querySelector('.container').classList.remove('loading');
		// return () => {
		// 	document.querySelector('.container').classList.add('loading');

		// }
	}, []);

	return (
		<SeoWrapper>
			<div className="container">
				<Header />
				<main id="page">
					<div id="titleWrapper">
						<div className="breadcrumbs">
							<AniLink
								className="nav-link-text"
								paintDrip hex="#EE82BF"
								to="/"
								duration={0.8}
							>
								Home
							</AniLink>
							{breadcrumbs !== '' ?
								breadcrumbs.map((breadcrumb, i) => {
									return (
										<span key={i}>
											<span className="separator">{'//'}</span>
											<AniLink
												to={breadcrumb[1] !== '#' ? `/${breadcrumb[1]}` : '#'}
												className="nav-link-text"
												cover bg="#EE82BF"
												direction="right"
												duration={0.8}
											>
												{breadcrumb[0]}
											</AniLink>
										</span>
									)
								})
							: ''}

						</div>
						<h1>{title}</h1>
						{subtitle !== '' ? 
							<h2>{subtitle}</h2>
						: ''}
					</div>	
					{children}
				</main>
				<Footer/>
			</div>
		</SeoWrapper>
	)
}

export default Layout